import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Google Cloud Next '19 in Tokyo にて、App Maker の活用事例を紹介しました",
  "date": "2019-08-26T05:24:57.000Z",
  "slug": "entry/2019/08/26/142457",
  "tags": ["medley"],
  "hero": "./2019_08_26.png",
  "heroAlt": "GCN19"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは、コーポレートエンジニアの若林です。`}</p>
    <p>{`普段はいわゆる社内 SE 的な領域を担当していますが、その名の通り`}<strong parentName="p">{`エンジニアの視点も持ったコーポレート機能`}</strong>{`という立ち位置で、社員の働く基盤や事業の発展を支えています。
過去のコーポレートエンジニアの取り組みや、マインドについては以下が参考になるかと思います。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.wantedly.com/companies/medley/post_articles/102877"
        }}>{`コーポレート IT 担当として会社の成長を支える兼松さんに「聞いてみた」`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/entry/2019/02/01/172457"
        }}>{`全社で本気になってリーンに ISMS の仕組みをつくった話`}</a></li>
    </ul>
    <p>{`今回もその取り組みの一環で、先日開催された `}<a parentName="p" {...{
        "href": "https://cloud.withgoogle.com/next/tokyo"
      }}>{`Google Cloud Next '19 in Tokyo`}</a>{` に、カスタマースピーカーという形で弊社執行役員の兼松と共に登壇させて頂きました。`}</p>
    <p>{`今回は、登壇させて頂いたセッション `}<a parentName="p" {...{
        "href": "https://cloud.withgoogle.com/next/tokyo/sessions?session=D2-4-S10"
      }}>{`Google Apps Script / AppMaker 最新アップデートと活用のヒント`}</a>{` について、セッションでお伝えした内容を含めたレポートを書きたいと思います。`}</p>
    <h1>{`Google Cloud Next '19 in Tokyo について`}</h1>
    <p>{`Google Cloud Next は、年一度 Google 主催で催される Google テクノロジーに関するカンファレンスです（今年は、サンフランシスコ、東京、ロンドンの世界 3 会場開催）`}</p>
    <ul>
      <li parentName="ul">{`日程；7/30(火) - 8/1(木)`}</li>
      <li parentName="ul">{`会場：東京プリンスホテルおよびザ・プリンス パークタワー東京`}</li>
      <li parentName="ul">{`イベントサイト：`}<a parentName="li" {...{
          "href": "https://cloud.withgoogle.com/next/tokyo"
        }}>{`Google Cloud Next '19 in Tokyo`}</a></li>
    </ul>
    <p>{`メドレー社内でも、G Suite を中心に Google Cloud の活用事例が増えてきていることもあり、社内の新しいアプリケーションプラットフォームとして採用している App Maker の話でスピーカー応募してみようということになりました。`}</p>
    <p>{`なお、登壇させて頂いたセッションですが、一般申込受付が始まってすぐに満席になってしまい、私が SNS でアナウンスしても既に満席で申し込めないような状態になっておりました・・・`}</p>
    <h1>{`会場の雰囲気`}</h1>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "東京タワーとの一枚"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190821/20190821171227.jpg",
        "alt": "20190821171227.jpg"
      }}></img><figcaption parentName="figure">{`東京タワーとの一枚`}</figcaption></figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "登壇の様子（背景と同色なのは偶然です）"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190821/20190821171259.jpg",
        "alt": "20190821171259.jpg"
      }}></img><figcaption parentName="figure">{`登壇の様子（背景と同色なのは偶然です）`}</figcaption></figure>
    <h1>{`セッション内容について`}</h1>
    <h2><a parentName="h2" {...{
        "href": "https://cloud.withgoogle.com/next/tokyo/sessions?session=D2-4-S10"
      }}>{`Google Apps Script / AppMaker 最新アップデートと活用のヒント`}</a></h2>
    <p>{`今回は、Google の深堀さん、嘉穂無線の太田さんとの合同セッションという形で登壇させて頂きました。`}</p>
    <p>{`まず Google の深掘さんが、「G Suite を拡張開発するテクノロジーの全体概要と活用事例」というテーマで、Google Apps Script / App Maker を含む G Suite 周辺の開発プラットフォームについて、位置付けの整理とグローバルでの活用事例、今後のアップデートについてお話されました。次に、嘉穂無線の太田さんが、「Google Apps Script の活用事例」というテーマで、Google Apps Script を活用して開発した社内システムについて、デモを交えて紹介されました。`}</p>
    <p>{`それを受けて、メドレーでは「Google App Maker 活用事例と開発 Tips」というテーマで、Google App Maker を活用して開発した社内システムを、デモを交えて紹介させて頂きました。`}</p>
    <p>{`App Maker は GA されてからまだ一年強ということもあって、Apps Script と比較してもオンラインに蓄積されているナレッジが非常に少なく（あってもほぼ英語）、現時点において日本で活用できている企業は本当に限られていると思っています。`}</p>
    <p>{`しかし実際に触っていく中でも非常に可能性を秘めたプラットフォームだと感じているので、日本でも活用する会社が増えてくれれば嬉しいという思いを込めて、今回プレゼンさせて頂きました。`}</p>
    <p>{`ここからは、実際にメドレーのパートでお話した内容を、振り返りも兼ねて書かせて頂ければと思います。`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://gsuite.google.co.jp/intl/ja/products/app-maker/"
      }}>{`Google App Maker`}</a>{` とは`}</h2>
    <p>{`一言で言うと...`}</p>
    <p><strong parentName="p">{`G Suite Business / Enterprise プランに含まれるローコードアプリケーション開発ツール`}</strong></p>
    <p>{`です！`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190821/20190821171328.png",
      "alt": "20190821171328.png"
    }}></img>
    <p>{`主に以下のような特徴があります。`}</p>
    <ul>
      <li parentName="ul">{`バックエンドのデータストアとして、RDB（Cloud SQL）が利用される`}</li>
      <li parentName="ul">{`開発言語は JavaScript をベースとしたスクリプト言語である Google Apps Script である`}</li>
      <li parentName="ul">{`G Suite のサービス（ユーザーディレクトリ、メール、ドライブ等）と容易に連携が可能である`}</li>
      <li parentName="ul">{`UI のカスタマイズの容易さと高い自由度がある`}</li>
    </ul>
    <h2>{`メドレーでの社内活用事例`}</h2>
    <p>{`メドレーでは、Google App Maker を活用して複数のアプリケーションを実装しています。`}</p>
    <p>{`今回は、そのうちの一つである社内稟議システムについての概要を紹介させて頂きました。（当日はデモも実施しているので、デモの内容を見たい方は`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=eOBcrwade48"
      }}>{`セッション動画`}</a>{`をご覧下さい）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190822/20190822102114.png",
      "alt": "20190822102114.png"
    }}></img>
    <h2>{`App Maker を使ったアプリケーション開発の流れと開発 Tips`}</h2>
    <p>{`App Maker はアプリケーション開発プラットフォームなので、開発の流れを以下の 3step に分割した上で、Tips を紹介させて頂きます。`}</p>
    <ol>
      <li parentName="ol">{`データモデルの定義`}</li>
      <li parentName="ol">{`UI 作成`}</li>
      <li parentName="ol">{`ビジネスロジック記述`}</li>
    </ol>
    <h3>{`1. データモデルの定義`}</h3>
    <p>{`はじめに、GUI ベースで RDB のメタデータを定義していきます。`}</p>
    <h4>{`モデル（テーブル）の主な設定項目`}</h4>
    <ul>
      <li parentName="ul">{`FIELDS：モデルのフィールド情報を定義`}</li>
      <li parentName="ul">{`DATASOURCES：データベースでいうところのビューに近い概念の設定`}</li>
      <li parentName="ul">{`RELATIONS：モデル間のリレーションを定義`}</li>
      <li parentName="ul">{`EVENTS：クライアントのデータベース操作に対するイベントスクリプトを定義`}</li>
      <li parentName="ul">{`SECURITY：モデルに対する権限を制御`}</li>
    </ul>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190821/20190821171355.png",
      "alt": "20190821171355.png"
    }}></img>
    <h4>{`データモデルの定義における開発 Tips`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`EVENTS の挙動`}</strong>{`：MODEL の設定項目なので一見データベーストリガーと思いがちですが、`}<a parentName="li" {...{
          "href": "https://developers.google.com/appmaker/models/events?hl=ja"
        }}>{`このイベントは、サーバーサイドスクリプトや外部からのデータベースレコード操作では、トリガーされません。`}</a>{`（クライアントサイドからの操作限定）サーバーサイドスクリプトや外部からの操作には、別途関数呼び出しを組み込む等の配慮が必要になります。`}</li>
      <li parentName="ul"><strong parentName="li">{`データベースへの更新反映タイミング`}</strong>{`：既定の設定ではクライアントでデータソースの情報が変更された場合に、サーバーに即時反映される動き(Auto Save Mode)になります。この動きを止めたければ `}<a parentName="li" {...{
          "href": "https://developers.google.com/appmaker/models/datasources?hl=ja#manual_save_mode"
        }}>{`Manual Save Mode`}</a>{` に切り替えることも可能ですが、コード記述量が大きく増えることになります。Auto Save Mode のままでも UI/UX の工夫で回避できたりするので、まずそこを検討するのが重要かなと思います。`}</li>
      <li parentName="ul"><strong parentName="li">{`細かな権限制御`}</strong>{`：GUI からできる権限制御設定は限られています。アプリケーション内での動的な権限制御や、フィールドの値に基づく細かな権限制御が必要な場合は、`}<a parentName="li" {...{
          "href": "https://developers.google.com/appmaker/models/datasources#query_builder"
        }}>{`Query Builder`}</a>{` や `}<a parentName="li" {...{
          "href": "https://developers.google.com/appmaker/models/datasources#query_script"
        }}>{`Query Script`}</a>{` による読み込み制御が必要になりますが開発コストが結構増加するので、他の回避策がないか事前に検討することをお勧めします。`}</li>
    </ul>
    <h3>{`2. UI 作成`}</h3>
    <p>{`次に、ウィジェット（画面を構成する部品）をドラッグ&ドロップで配置して画面を作成していきます。`}</p>
    <p>{`一般的に必要となるボタンやドロップダウンに加えて、G Suite ならではの `}<a parentName="p" {...{
        "href": "https://developers.google.com/appmaker/ui/input-widgets?hl=ja#header_13"
      }}>{`User Picker`}</a>{` や `}<a parentName="p" {...{
        "href": "https://developers.google.com/appmaker/ui/input-widgets?hl=ja#header_12"
      }}>{`Drive Picker`}</a>{` 、更には`}<a parentName="p" {...{
        "href": "https://developers.google.com/appmaker/ui/popups?hl=ja"
      }}>{`リッチなポップアップ、ダイアログ等`}</a>{`も利用可能です。`}</p>
    <p>{`スタイルに関しては、`}<a parentName="p" {...{
        "href": "https://developers.google.com/appmaker/ui/styles?hl=ja#Variants"
      }}>{`バリアント`}</a>{`を利用することで、統一感のあるスタイル適用が容易に可能になっています`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190821/20190821171425.png",
      "alt": "20190821171425.png"
    }}></img>
    <h4>{`UI 作成における開発 Tips`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`組み込みのマテリアルデザインアイコン`}</strong>{`：ラベルやボタンの text を特定の文字列にすると、元々組み込まれているマテリアルデザインアイコンを利用することが可能です。（`}<a parentName="li" {...{
          "href": "https://material.io/resources/icons/?style=baseline"
        }}>{`文字列とアイコンの対応表`}</a>{`）一般的に利用されているようなアイコンはほとんど用意されているので、ガンガン活用していきましょう。`}</li>
    </ul>
    <h3>{`3. ビジネスロジック記述`}</h3>
    <p>{`最後に、2 種類のスクリプト（Client Script, Server Script）を利用して、ビジネスロジックを記述していきます。（アイテム生成とか、読み込みとかは基本的に記述不要）`}</p>
    <p>{`Client Script から Server Script を呼び出したい場合は、 Google Apps Script 同様、以下のような形で実行が可能になっています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`google`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`script`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`run`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ServerSideFunction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190821/20190821171501.png",
      "alt": "20190821171501.png"
    }}></img>
    <h4>{`ビジネスロジック記述における開発 Tips`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`外部公開できない`}</strong>{`：Google Apps Script で言うところの、doGet/doPost 関数のような Web API は、App Maker のスクリプト内に作ることができないようになっています。外部システムとの連携が必要な場合、Cloud Function 等を介して、Cloud SQL を直接操作する必要があります。（トランザクション処理等は複雑化します）`}</li>
      <li parentName="ul"><strong parentName="li">{`ローカルの開発環境と上手く連携できない`}</strong>{`：Google Apps Script で利用可能な `}<a parentName="li" {...{
          "href": "https://github.com/google/clasp"
        }}>{`clasp`}</a>{` は、現時点で App Maker には対応していません。バージョン管理、デプロイ管理機能は開発コンソールに実装されていますが、コードレベルでの差分レビュー等を実施したい場合は、現時点では少しめんどくさいです。`}</li>
      <li parentName="ul"><strong parentName="li">{`定期実行トリガーを GUI で設定できない`}</strong>{`：Google Apps Script では GUI から設定が可能な、「時間ベース」のトリガーが GUI で簡単に設定できません。設定が必要な場合は、以下のようなスクリプトベースで設定する必要があります。`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`ScriptApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`newTrigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"functionName"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`timeBased`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`date`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`App Maker をこれから始める人向けの情報`}</h3>
    <p>{`なお、今回紹介させて頂いた内容は App Maker を全く触ったことがない人には少し難しい内容だと思っています。`}</p>
    <p>{`知識がゼロからの方は、以下のナレッジを元に是非一度チュートリアルなどで簡単に触ってから、改めて読んで頂けると理解が深まるかと思います。`}</p>
    <h4>{`アプリ作成の一連の流れを掴む`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=0B8Pao5vU_0"
        }}>{`How to Build Enterprise Workflows with App Maker (Cloud Next '18)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=hfPrGNO6Frs"
        }}>{`Improve Processes by integrating App Maker, Data Studio and GCP (Cloud Next '19)`}</a></li>
    </ul>
    <h4>{`実際に触ってみる`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.google.com/appmaker/tutorials/"
        }}>{`チュートリアル`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.google.com/appmaker/templates/"
        }}>{`テンプレート`}</a></li>
    </ul>
    <h4>{`情報共有プラットフォームを活用する`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.com/questions/tagged/google-app-maker"
        }}>{`stack overflow [google-app-maker] タグ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://groups.google.com/forum/#!forum/appmaker-users"
        }}>{`Google グループ[App Maker Users]`}</a></li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`今回紹介させて頂いた Google App Maker は、G Suite をディープに利用している企業であれば、Google Apps Script に匹敵する強力な社内アプリケーションプラットフォームになると感じています。`}</p>
    <p>{`App Maker のようなローコード/ノーコード開発プラットフォームは既に市場にいくつか出ており、Google App Maker はその中でも比較的コーディング・技術力が求められるプラットフォームだと思います。しかし、その分既存社内システムと親和性の高い高度な自動化が実現しやすいプラットフォームでもあると思うので、臆せずにチャレンジする価値は十分にあると思います。`}</p>
    <p>{`なお、セッション中に紹介させて頂いた稟議アプリケーションは、App Maker に対する知識が 0 の状態から 1~2 ヶ月程で Slack との連携も含めて 1 人で実装できました。（RDB や GAS に対する知識は、ある程度習得済みの状態が前提にはなっています）`}</p>
    <p>{`GA 直後ということもあってインターネット上のナレッジが少ない中、探り探りに得られた活用のヒントを今回の場でお話しさせて頂きましたが、引き続き App Maker というプラットフォームを上手く利用していくには、ユーザーコミュニティの盛り上がりが欠かせないと思っていますので、引き続き情報発信等を通じて盛り上げていければと考えております。`}</p>
    <p>{`最後になりますが、メドレーではこのような市場に出て間もないテクノロジーでもチャレンジしていける風土があります。こういったチャレンジを求めている方は、ぜひご応募下さい。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story.html"
      }}>{`https://www.medley.jp/team/creator-story.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      